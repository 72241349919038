import { useAuthContext } from "./useAuthContext";

export const useLogOut = () => {
 
    const { dispatch } = useAuthContext();


    const logout = () => {

        localStorage.removeItem('userData')

        dispatch({type: "LOGOUT"})
    }

    return {logout}
 }