import React from "react";
import {  NavLink, useNavigate } from "react-router-dom";
import { useLogOut } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const Navbar = () => {
const{user, loading} = useAuthContext();
const {logout} = useLogOut()
const navigate = useNavigate();
const handleClick = () => {
    logout()
    navigate("/")
}
    return (<>
        {loading ? <div className="d-flex justify-content-center">Loading....</div>
       : <div className="navbar">
            <div className="links">
        <NavLink  className="active" to="/miqaat-history">Miqaat History</NavLink>
   {user.role==="superAdmin" && <NavLink  className="active mx-5" to="/admin">Add User</NavLink>}
            </div>    
        <div className="username"> Hello, {user.username !== null ? user.username : ''}
        </div>
        <button  className="logout" onClick={handleClick}>Log out</button> 
        </div>
        
        }
       
        </>
    )
};

export default Navbar;