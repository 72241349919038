import React, { useEffect, useState } from "react";
import axios from "axios";

const ZakereenFilter = ({ seatNumber }) => {
  const [filterDetail, setFilterDetail] = useState({
    ITSID: "",
    Name: "",
    Member: "",
    Party: "",
    City: "",
    State: "",
    Jamiaat: "",
    Jamaat: "",
    Aprroval: "",
    IsActive: "",
    Grade: "",
  });

  const [filterTable, setFilterTable] = useState([]);
  const [jamaatDetails, setJamaatDetails] = useState([]);
  const [jamiatDetails, setJamiatDetails] = useState([]);
  const [jamaatFilter, setJamaatFilter] = useState([]);

  const fetchFilterData = async () => {
    const valueJsonStringify = JSON.stringify(filterDetail);
    // try {
    const newData = await axios
      .post(
        "https://www.idaratalzakereen.org/api/MuminDetails/GetMemberDetails",
        {
          // mode: 'no-cors',
          "content-type": "application/json",

          body: filterDetail,
        }
      )
      .then((res) => res.data)
      .catch((error) => error.message);
  };

  const handleClick = () => {
    fetchFilterData();
  };
  const getJamiatDetails = async () => {
    const result = await axios
      .get(
        "https://allocations.idaratalzakereen.org:8080/api/miqaat-history/getJamiatDetails"
      )
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        return res.message;
      });
    setJamiatDetails(result.data.jamiatData);
  };

  const resetFilter = () => {
    setFilterDetail({
      ITSID: "",
      Name: "",
      Member: "",
      Party: "",
      City: "",
      State: "",
      Jamiaat: "",
      Jamaat: "",
      Aprroval: "",
      IsActive: "",
      Grade: "",
    });
    setFilterTable([]);
  };

  const getJamaatDetails = async () => {
    const result = await axios
      .get(
        `https://allocations.idaratalzakereen.org:8080/api/miqaat-history/getJamaatDetails`
      )
      .then((res) => {
        return res.data;
      })
      .catch((res) => {
        return res.message;
      });
    setJamaatDetails(result.data.jamaatData);
  };

  useEffect(() => {
    getJamiatDetails();
    getJamaatDetails();
  }, []);

  const handleChange = (e) => {
    setFilterDetail((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const maleFilterTable = filterTable.filter((item) => item.Gender === "M");

  useEffect(() => {
    const jamaatFil = jamaatDetails.filter(
      (item) => item.Jamiaat_ID.toString() === filterDetail.Jamiaat
    );
    setJamaatFilter(jamaatFil);
  }, [filterDetail.Jamiaat]);

  return (
    <div className="filters-page miqaat">
      <div className="miqaat-form">
        <div className="row">
          <div className="col-md-3 col-6">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              value={filterDetail.Name}
              placeholder="Name"
              name="Name"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">ITS</label>
            <input
              type="text"
              className="form-control"
              placeholder="ITS"
              value={filterDetail.ITSID}
              name="ITSID"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Member</label>
            <input
              type="text"
              className="form-control"
              placeholder="Member"
              value={filterDetail.Member}
              name="Member"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Party</label>
            <input
              type="text"
              className="form-control"
              placeholder="party"
              value={filterDetail.Party}
              name="Party"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 col-6">
            <label className="form-label">City</label>
            <input
              type="text"
              className="form-control"
              placeholder="City"
              value={filterDetail.City}
              name="City"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">State</label>
            <input
              type="text"
              className="form-control"
              placeholder="State"
              name="State"
              value={filterDetail.State}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Jamiat</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="Jamiaat"
              value={filterDetail.Jamiaat}
            >
              <option defaultValue="">Select Jamiat</option>
              {jamiatDetails.length !== 0 && jamaatDetails ? (
                jamiatDetails.map((item) => {
                  return (
                    <option key={item.Jamiaat_ID} value={item.Jamiaat_ID}>
                      {item.Jamiaat}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Jamaat</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="Jamaat"
              value={filterDetail.Jamaat}
            >
              <option defaultValue="">ALL Jamaat</option>
              {jamaatFilter.length !== 0 && jamaatFilter ? (
                jamaatFilter.map((item) => {
                  return (
                    <option key={item.Jamaat_ID} value={item.Jamaat_ID}>
                      {item.Jamaat}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3 col-6">
            <label className="form-label">Approval</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="Approval"
              value={filterDetail.Aprroval}
            >
              <option defaultValue="Please Select">Please Select</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Waiting">Waiting</option>
            </select>
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Is Active</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="IsActive"
              value={filterDetail.IsActive}
            >
              <option defaultValue="Please Select">Please Select</option>
              <option value="Active">Active</option>
              <option value="De-Active">De-Active</option>
            </select>
          </div>
          <div className="col-md-3 col-6">
            <label className="form-label">Grade</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="Grade"
              value={filterDetail.Grade}
            >
              <option defaultValue="Select One">Select One</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="K">K</option>
              <option value="R">R</option>
              <option value="T">T</option>
              <option value="Y">Y</option>
              <option value="Z">Z</option>
            </select>
          </div>
          <div className="col-md-3 d-flex" style={{ alignItems: "baseline" }}>
            <button className="zakereen-submit" onClick={handleClick}>
              Submit
            </button>
            <button className="btn btn-link" onClick={resetFilter}>
              Reset Filter
            </button>
          </div>
        </div>
      </div>

      <div className="miqaatTable table mt-4" style={{ overflow: "scroll" }}>
        {maleFilterTable.length !== 0 ? (
          <table>
            <thead className="miqaatTableHeader">
              <th>S.No</th>
              <th style={{ minWidth: 290 }}>Full Name</th>
              <th>ITS</th>
              <th>Jamaat</th>
              <th>Grade</th>
              <th>Approval</th>
              <th>Active</th>
            </thead>
            <tbody className="miqaatTableBody">
              {maleFilterTable.map((item, index) => {
                return (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>
                      {item.strName} {item.strSurName}
                    </td>
                    <td>{item.strITSID}</td>
                    <td>
                      {item.strMohallah === null ? "-" : item.strMohallah}
                    </td>
                    <td>{item.strGrade}</td>
                    <td>
                      {item.blnIsApproved === 1 ? "Approved" : "Rejected"}
                    </td>
                    <td>{item.IsActive ? "Active" : "Not Active"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h3 className="text-center mt-4">No Data Found</h3>
        )}
      </div>
    </div>
  );
};

export default ZakereenFilter;
