import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import delete_image from "../images/delete.png";
import ItsData from "../components/popoverTooltip";
import axios from "axios";
import _, { assign } from "lodash";
import ZakereenFilter from "./ZakereenFilter";
import { CSVLink } from "react-csv";
import Popup from "reactjs-popup";
import GeneratePDF from "../components/GeneratePDF";
import { BASE_URL } from "../components/utils/Url";
import { useAuthContext } from "../hooks/useAuthContext";
import dummyImg from "../images/dummy.jpg";
import comment_img from "../images/comment.png";

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const SeatChart = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [seatArray, setSeatArray] = useState([]);
  const [miqaatID, setMiqaatID] = useState(id);
  const [miqaatDropdown, setMiqaatDropdown] = useState([]);
  const [allocationCount, setAllocationCount] = useState(0);
  const [temporaryCount, setTemporaryCount] = useState(0);
  const [permanentCount, setPermanentCount] = useState(0);
  const [vacantCount, setVacantCount] = useState(0);
  const [error, setError] = useState("");
  const [arr, setArr] = useState([]);
  const [imageBase, setImageBase] = useState({});
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [miqaatDetail, setMiqaatDetail] = useState({
    miqaatName: "",
    hijriDate: "",
    date: "",
    city: "",
    alphabeticFirstRange: "",
    alphabeticLastRange: "",
    numericFirstRange: "",
    numericLastRange: "",
    seatChart: seatArray,
    allocationCount: allocationCount,
  });
  const fetchAllMiqaatData = async () => {
    const result = await axios
      .get(`${BASE_URL}/api/miqaat-history`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        return res.data.data.miqaatData;
      })
      .catch((res) => {
        setError(res.message);
        return res.message;
      });
    setMiqaatDropdown(result);
    if (miqaatDropdown.length !== 0) {
      setError("");
    }
  };

  const handleChange = (e) => {
    setMiqaatID(e.target.value);
  };

  useEffect(() => {
    fetchAllMiqaatData();
  }, []);

  // const toggleTooltip = () => ref.current.toggle();
  const fetchMiqaatData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/miqaat-history/seatchart/${miqaatID}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const data = res.data.data;
      setSeatArray(data.seatDetails);
      setMiqaatDetail(data.miqaatDetails);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    if (miqaatID) {
      fetchMiqaatData();
    }
  }, [miqaatID]);

  useEffect(() => {
    if (miqaatDetail && seatArray) {
      let value = seatArray;
      alpha(
        miqaatDetail.alphabeticFirstRange.toUpperCase(),
        miqaatDetail.alphabeticLastRange.toUpperCase()
      );
      Numeric(miqaatDetail.numericFirstRange, miqaatDetail.numericLastRange);
      setSeatArray(value);
      newSeatArrangement(value);
    }
  }, [miqaatDetail]);

  let numericArr = [];
  const Numeric = (a, b) => {
    for (let i = a; i <= b; i++) {
      numericArr.push(i);
    }
    return numericArr;
  };
  let newArr = [];
  const alpha = (a, b) => {
    let index = alphabets.indexOf(a);
    if (b.length < 2 && a.length < 2) {
      for (let i = index; i <= alphabets.indexOf(b); i++) {
        newArr.push(alphabets[i]);
      }
    } else {
      for (let i = index; i < alphabets.length; i++) {
        newArr.push(alphabets[i]);
      }
      let k = "";
      for (let i = 0; i < alphabets.length; i++) {
        for (let j = 0; j < alphabets.length; j++) {
          k = alphabets[i] + alphabets[j];
          newArr.push(k);
          if (k === b) {
            break;
          }
        }
        if (k === b) {
          break;
        }
      }
    }

    return newArr;
  };

  const newSeatArrangement = (value) => {
    let newSeatArr = [];
    let a = 0;
    if (value.length !== 0) {
      for (let j = 0; j < newArr.length; j++) {
        let newArray = [];
        for (let i = 0; i < numericArr.length; i++) {
          newArray = [...newArray, value[a]];
          a++;
        }
        newSeatArr.push(newArray);
      }
      setArr(newSeatArr);
      return newSeatArr;
    }
  };

  const getItsData = (data, seatStatus, seatId) => {
    const dataToUpdate = [
      {
        seatStatus: seatStatus,
        ITSID: data.ITSID,
        muminName: data.Name,
        Jamaat: data.Jamaat,
        Jamiat: data.Jamiat,
        muminAge: data.Age,
        muminPhotograph: data.Photo,
        seatAllocationId: seatId,
        grade: data.Grade,
        contactNum: data.Contact,
        miqaat_id: miqaatID,
      },
    ];
    seatAllocation(dataToUpdate);
  };
  const seatAllocation = async (value) => {
    const dataToSend = value;

    try {
      await axios
        .patch(
          `${BASE_URL}/api/miqaat-history/seatAllocation/update`,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => res);
      fetchMiqaatData();
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteHandler = async (id, itsId) => {
    const data = {
      id: id,
      ITSID: itsId,
    };
    try {
      await axios
        .post(
          `${BASE_URL}/api/miqaat-history/deleteItsData`,
          data,

          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {});
      fetchMiqaatData();
    } catch (error) {
      console.log(error.message);
    }
  };
  const toggleSeatStatus = async (seat) => {
    const newStatus =
      seat.seatStatus === "permanent"
        ? "temporary"
        : seat.seatStatus === "temporary"
        ? "permanent"
        : seat.seatStatus;

    const dataToUpdate = {
      ITSID: seat.ITSID,
      muminName: seat.Name,
      Jamaat: seat.Jamaat,
      Jamiat: seat.Jamiat,
      muminAge: seat.seatAge,
      muminPhotograph: seat.Photo,
      seatAllocationId: seat.id,
      grade: seat.Grade,
      contactNum: seat.Contact,
      seatStatus: newStatus,
      miqaat_id: miqaatID,
    };

    try {
      await axios.patch(
        `${BASE_URL}/api/miqaat-history/seatAllocation/update`,
        [dataToUpdate],
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchMiqaatData();
    } catch (error) {
      console.log(error.message);
    }
  };

  const commentHandler = async (seat) => {
    setIsLoading(true);
    const dataToUpdate = {
      ITSID: seat.ITSID,
      muminName: seat.Name,
      Jamaat: seat.Jamaat,
      Jamiat: seat.Jamiat,
      muminAge: seat.seatAge,
      muminPhotograph: seat.Photo,
      seatAllocationId: seat.id,
      grade: seat.Grade,
      contactNum: seat.Contact,
      seatStatus: seat.seatStauts,
      miqaat_id: miqaatID,
      comment: comment,
    };

    try {
      await axios.patch(
        `${BASE_URL}/api/miqaat-history/seatAllocation/update`,
        [dataToUpdate],
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchMiqaatData();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removedBlocked = seatArray.filter((item) => !item.seatCheckStatus);

  const countSeats = () => {
    let totalAllocated = 0;
    let tempCount = 0;
    let permCount = 0;

    seatArray.forEach((seat) => {
      if (seat.seatStatus === "temporary") {
        tempCount++;
      } else if (
        seat.seatStatus === "permanent" ||
        seat.seatStatus === "final"
      ) {
        permCount++;
      }
    });

    totalAllocated = tempCount + permCount;
    const totalSeats = seatArray.length;
    const vacantSeats = totalSeats - totalAllocated;

    setAllocationCount(totalAllocated);
    setTemporaryCount(tempCount);
    setPermanentCount(permCount);
    setVacantCount(vacantSeats);
  };
  const extractITSID = (arr) => {
    const ITSIDArray = [];
    arr.forEach((row) => {
      row.forEach((item) => {
        if (item && item.ITSID) {
          ITSIDArray.push(item.ITSID);
        }
      });
    });
    return ITSIDArray;
  };
  const imageArray = extractITSID(arr);
  useEffect(() => {
    (async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/image-to-base64`, {
              "imageUrl" : imageArray,
            });
            setImageBase(response.data.data);
        } catch (error) {
            console.error('Error converting images:', error);
        }
    })();
}, [arr]);
  useEffect(() => {
    countSeats();
  }, [seatArray]);
  return (
    <div id="report" className="seat-allocation miqaat">
      <h1>
        {miqaatDetail.miqaatName} {miqaatDetail.city}
      </h1>
      <div className="seatChartDate">
        <span className="seatChartHdate">{miqaatDetail.hijriDate}</span>
        <span className="seatChartEdate">{miqaatDetail.date}</span>
      </div>
      <div className="exportExcel">
        <div style={{ display: "flex", gap: "2rem", padding: "1rem" }}>
          <span
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            Zakereen Filter
          </span>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/miqaat-history/seatchart/${miqaatID}/bulkallocation`}
          >
            Bulk Allocation
          </Link>
        </div>
        <div>
          <select className="form-select" onChange={handleChange}>
            <option defaultValue>Select Miqaat</option>
            {miqaatDropdown.length !== 0 ? (
              miqaatDropdown.map((item) => {
                return (
                  <option key={item.miqaatId} value={item.miqaatId}>
                    {item.miqaatName}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
          <span
            onClick={() => GeneratePDF(miqaatDetail, arr, imageBase)}
            style={{
              marginRight: 16,
              marginTop: 16,
              color: "#0d6efd",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Export Pdf
          </span>
          <span className="mt-4">
            <CSVLink
              data={removedBlocked}
              filename={`${miqaatDetail.miqaatName}.csv`}
            >
              Export Excel
            </CSVLink>
          </span>
        </div>
      </div>
      <div className="seat-count">
        <span>Total Allocated : {allocationCount}</span>
        <span>Temporary Allocated : {temporaryCount}</span>
        <span>Permanent Allocated : {permanentCount}</span>
        <span>Vacant : {vacantCount}</span>
      </div>
      <div className="row seat-chart mt-4 main-seat-chart">
        {miqaatDetail !== null ? (
          arr.map((item, index) => {
            return (
              <div
                key={index + 1}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {item.map((secondItem) => {
                  return (
                    secondItem !== undefined &&
                    (secondItem.seatCheckStatus ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box blocked"
                      >
                        <span>This Seat is Blocked</span>
                      </div>
                    ) : secondItem.seatStatus === "final" ||
                      secondItem.seatStatus === "permanent" ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box permanent"
                      >
                        
                        <Popup
                          trigger={
                            <span className="temporary-permanent-seatNum">
                              {secondItem.seatNum}
                            </span>
                          }
                          position="bottom"
                        >
                          <ItsData
                            getItsData={getItsData}
                            seatDetail={secondItem}
                            seatArray={seatArray}
                          />
                        </Popup>
                        <Popup
                          trigger={
                            <img
                              className="comment_button"
                              src={comment_img}
                              alt=""
                            />
                          }
                          position="bottom"
                          onOpen={() => setComment(secondItem.comment !==null ? secondItem.comment : "")}
                        >
                          <div className="itsTooltip">
                            <input
                              type="text"
                              placeholder="Enter Comment"
                              className="popover-search"
                              onChange={(e) => setComment(e.target.value)}
                              value={comment}
                              required
                            />
                            <button
                              className="mx-2 btn btn-warning text-white"
                              onClick={() => commentHandler(secondItem)}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <strong> Add</strong>
                              )}
                            </button>
                            {/* <div className="tooltip-body mt-4">
                              <p>{secondItem.comment}</p>
                            </div> */}
                          </div>
                        </Popup>

                        <img
                          className="profile-image"
                          // src={!secondItem.muminPhotograph ? dummyImg : secondItem.muminPhotograph}
                          src={
                            !`${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`
                              ? dummyImg
                              : `${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`
                          }
                          alt=""
                        />
                        <span className="muminName-its">
                          {secondItem.muminName}
                        </span>
                        <span className="muminName-its">
                          {secondItem.ITSID}
                        </span>
                        <div className="seatFooter">
                        <img
                          onClick={() => {
                            deleteHandler(secondItem.id, secondItem.ITSID);
                          }}
                          className="delete_button"
                          src={delete_image}
                          alt=""
                        />
                      <span className="muminGrade">{secondItem.grade ? secondItem.grade : "-"}</span>
                        <div className=" toggle_btn form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`required_${secondItem.seatNum}`}
                            checked={
                              secondItem.seatStatus === "permanent" || secondItem.seatStatus === "final" || false
                            }
                            onChange={() => toggleSeatStatus(secondItem)}
                          />
                        </div>
                        </div>
                        
                      </div>
                    ) : secondItem.seatStatus === "temporary" ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box temporary"
                      >
                        <Popup
                          trigger={
                            <span className="temporary-permanent-seatNum">
                              {secondItem.seatNum}
                            </span>
                          }
                          position="bottom"
                        >
                          <ItsData
                            getItsData={getItsData}
                            seatDetail={secondItem}
                            seatArray={seatArray}
                          />
                        </Popup>
                        <Popup
                          trigger={
                            <img
                              className="comment_button"
                              src={comment_img}
                              alt=""
                            />
                          }
                          position="bottom"
                          onOpen={()=>setComment(secondItem.comment !==null ? secondItem.comment : "")}
                        >
                          <div className="itsTooltip">
                            <input
                              
                              type="text"
                              placeholder="Enter Comment"
                              className="popover-search"
                              onChange={(e) =>{
                                 setComment(e.target.value)}}
                              value={comment}
                              required
                            />
                            <button
                              className="mx-2 btn btn-warning text-white"
                              onClick={() => commentHandler(secondItem)}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <strong> Add</strong>
                              )}
                            </button>
                            {/* <div className="tooltip-body mt-4">
                              <p>{secondItem.comment}</p>
                            </div> */}
                          </div>
                        </Popup>
                        <img
                          className="profile-image"
                          // src={!secondItem.muminPhotograph ? dummyImg : secondItem.muminPhotograph}
                          src={
                            !`${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`
                              ? dummyImg
                              : `${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`
                          }
                          alt=""
                        />
                        <span className="muminName-its">
                          {secondItem.muminName}
                        </span>
                        <span className="muminName-its">
                          {secondItem.ITSID}
                        </span>
                        <div className="seatFooter">
                        <img
                          onClick={() => {
                            deleteHandler(secondItem.id, secondItem.ITSID);
                          }}
                          className="delete_button"
                          src={delete_image}
                          alt=""
                        />
                      <span className="muminGrade">{secondItem.grade ? secondItem.grade : "-"}</span>
                        <div className=" toggle_btn form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`required_${secondItem.seatNum}`}
                            checked={
                              secondItem.seatStatus === "permanent" || secondItem.seatStatus === "final" || false
                            }
                            onChange={() => toggleSeatStatus(secondItem)}
                          />
                        </div>
                        </div>
                      </div>
                    ) : (
                      <div key={secondItem.seatNum} className="seat-box">
                        <Popup
                          trigger={
                            <span className="pending">
                              {secondItem.seatNum}
                            </span>
                          }
                          position="bottom"
                        >
                          <ItsData
                            getItsData={getItsData}
                            seatDetail={secondItem}
                            seatArray={seatArray}
                          />
                        </Popup>
                      </div>
                    ))
                  );
                })}
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>

      <div
        style={{ width: 1050 }}
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Zakereen Filter
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body mt-3">
          <ZakereenFilter seatNumber={seatArray} />
        </div>
      </div>
    </div>
  );
};

export default SeatChart;
