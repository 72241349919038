import React from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Miqat from "./pages/Miqat";
import SeatChart from "./pages/SeatChart";
import Login from "./pages/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import BulkAllocation from "./pages/BulkAllocation";
import AdminPanel from "./pages/AdminPanel";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}



function App() {
//   const {currentUser} = useContext(AuthContext)
//   const RequireAuth = ({children}) => {
//   return currentUser ? children: <Navigate to="/login" />;
// }
const { user, loading } = useAuthContext();

    

const router = createBrowserRouter([
  {
    path: "/",
    element: !user ? <Login /> : <Navigate to="/miqaat-history" />
  },
  {
    path:"/admin",
    element: user && user.role==="superAdmin" ? <AdminPanel/> :  <Navigate to="/" />
  },
  {
    path: "/miqaat-history",
    element: <Layout />,
    children : [
      
      {
        path: "/miqaat-history",
        element: user ? <Miqat /> : <Navigate to="/" />
      },
      {
        path: "/miqaat-history/seatchart/:id",
        element: user ? <SeatChart /> : <Navigate to="/" />
      },
      {path: "/miqaat-history/seatchart/:id/bulkallocation",
      element: user ? <BulkAllocation/> : <Navigate to="/"/>
    }
    ]
  }
 
]);



  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
