import React, { useState, useEffect } from "react";
import {  NavLink } from "react-router-dom";
import { BASE_URL } from "../components/utils/Url";
import { useAuthContext } from "../hooks/useAuthContext";
import Navbar from "../components/Navbar";

const AdminPanel = () => {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [selectedUser, setSelectedUser] = useState({ username: "", id: null });
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (user.token) {
      loadUsers();
    } else {
      console.error("User token is not available");
    }
  }, [user.token]);

  const loadUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/user/fetch`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value.trim(), // Trim the input values
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newUser.password !== newUser.confirmPassword) {
      setMessage("Passwords do not match!");
      return;
    }

    const { username, password } = newUser;
    const userToSubmit = { username, password };

    try {
      const response = await fetch(`${BASE_URL}/api/register-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(userToSubmit),
      });
      const result = await response.json();
      setMessage(result.message);
      setNewUser({ username: "", password: "", confirmPassword: "" });
      loadUsers();
    } catch (error) {
      console.error("Error adding user:", error);
      setMessage("Error adding user");
    }

    // Auto-hide message after 2 seconds
    setTimeout(() => setMessage(""), 2000);
  };

  const deleteUser = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/delete-user/${userId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const result = await response.json();
      setMessage(result.message);
      loadUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      setMessage("Error deleting user");
    }

    // Auto-hide message after 2 seconds
    setTimeout(() => setMessage(""), 2000);
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match!");
      return;
    }

    const password = newPassword.trim();

    try {
      const response = await fetch(`${BASE_URL}/api/reset-user-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ username: selectedUser.username, password }),
      });
      const result = await response.json();
      setMessage(result.message);
      setShowModal(false);
      setNewPassword("");
      setConfirmNewPassword("");
      setError("");
      loadUsers();
    } catch (error) {
      console.error("Error changing password:", error);
      setMessage("Failed to change password");
      setShowModal(false);
      setNewPassword("");
      setConfirmNewPassword("");
      setError("");
    }

    // Auto-hide message after 2 seconds
    setTimeout(() => setMessage(""), 2000);
  };

  const openChangePasswordModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setNewPassword("");
    setConfirmNewPassword("");
    setError("");
  };

  return (
    <div className="container">
    <Navbar />
      <div className="card m-auto my-5" style={{ width: "25rem" }}>
        <div className="card-body">
          <h5 className="card-title">Add User</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={newUser.username}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={newUser.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="text-center my-2">
              <button type="submit" className="btn btn-primary">
                Add User
              </button>
            </div>
          </form>
        </div>
      </div>

      {message && (
        <div className="alert alert-info text-center mt-4">{message}</div>
      )}

      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>S. NO.</th>
            <th>Username</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.username}</td>
              <td>
                <div className="text-center d-flex justify-content-center align-items-center gap-3">
                  <button
                    className="btn btn-secondary"
                    onClick={() => openChangePasswordModal(user)}
                  >
                    Change Password
                  </button>
                  {!(user.role === "superAdmin") ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteUser(user.id)}
                    >
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div
          className="modal fade show d-block"
          id="changePasswordModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="changePasswordModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="changePasswordModalLabel">
                  Change Password
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedUser.username}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value.trim())}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={confirmNewPassword}
                      onChange={(e) =>
                        setConfirmNewPassword(e.target.value.trim())
                      }
                    />
                    {error && <div className="text-danger mt-2">{error}</div>}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlePasswordChange}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
