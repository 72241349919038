import React, { useEffect, useState } from "react";
import _ from "lodash";
import Popup from "reactjs-popup";
import delete_image from "../images/delete.png";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import image from "../images/dummy.jpg";
import { BASE_URL } from "../components/utils/Url";
import { useAuthContext } from "../hooks/useAuthContext";
import ReactAudioPlayer from "react-audio-player";

const BulkAllocation = () => {
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""); // Array of alphabets
  const { user } = useAuthContext();
  const { id } = useParams();
  const [miqaatID, setMiqaatID] = useState(id); // Miqaat ID from URL params
  const [seatArray, setSeatArray] = useState([]); // Array of seat details
  const [arr, setArr] = useState([]); // 2D array of seats for rendering
  const [itsNumber, setItsNumber] = useState(""); // Input string for ITS numbers
  const [itsNumbers, setItsNumbers] = useState([]); // Array of ITS numbers
  const [itsData, setItsData] = useState([]); // Array of ITS details
  const [fetchedNumbers, setFetchedNumbers] = useState([]); // Fetched ITS numbers
  const [selectedSeats, setSelectedSeats] = useState([]); // Selected seat IDs
  const [selectedRows, setSelectedRows] = useState([]); // Selected row indices
  const [selectedColumns, setSelectedColumns] = useState([]); // Selected column indices
  const [seatStatus, setSeatStatus] = useState("temporary"); // Seat status (default: "permanent")
  const [isLoading, setIsLoading] = useState(false); // Loading state for submit button
  const [miqaatDetail, setMiqaatDetail] = useState({
    miqaatName: "",
    hijriDate: "",
    date: "",
    city: "",
    alphabeticFirstRange: "",
    alphabeticLastRange: "",
    numericFirstRange: "",
    numericLastRange: "",
    seatChart: seatArray,
    allocationCount: 0,
  }); // Miqaat details object

  // Fetch miqaat data from backend
  const fetchMiqaatData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/miqaat-history/seatchart/${miqaatID}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const data = res.data.data;
      setSeatArray(data.seatDetails);
      setMiqaatDetail(data.miqaatDetails);
    } catch (error) {
      return error.message;
    }
  };

  // Fetch miqaat data on component mount
  useEffect(() => {
    if (miqaatID) {
      fetchMiqaatData();
    }
  }, [miqaatID]);

  // Handle seat arrangement and ranges on miqaat detail update
  useEffect(() => {
    if (miqaatDetail && seatArray) {
      let value = seatArray;
      alpha(
        miqaatDetail.alphabeticFirstRange.toUpperCase(),
        miqaatDetail.alphabeticLastRange.toUpperCase()
      );
      Numeric(miqaatDetail.numericFirstRange, miqaatDetail.numericLastRange);
      setSeatArray(value);
      newSeatArrangement(value);
    }
  }, [miqaatDetail]);

  // Generate numeric range array
  let numericArr = [];
  const Numeric = (a, b) => {
    for (let i = a; i <= b; i++) {
      numericArr.push(i);
    }
    return numericArr;
  };

  // Generate alphabetic range array
  let newArr = [];
  const alpha = (a, b) => {
    let index = alphabets.indexOf(a);
    if (b.length < 2 && a.length < 2) {
      for (let i = index; i <= alphabets.indexOf(b); i++) {
        newArr.push(alphabets[i]);
      }
    } else {
      for (let i = index; i < alphabets.length; i++) {
        newArr.push(alphabets[i]);
      }
      let k = "";
      for (let i = 0; i < alphabets.length; i++) {
        for (let j = 0; j < alphabets.length; j++) {
          k = alphabets[i] + alphabets[j];
          newArr.push(k);
          if (k === b) {
            break;
          }
        }
        if (k === b) {
          break;
        }
      }
    }

    return newArr;
  };

  // Arrange seats into a 2D array
  const newSeatArrangement = (value) => {
    let newSeatArr = [];
    let a = 0;
    if (value.length !== 0) {
      for (let j = 0; j < newArr.length; j++) {
        let newArray = [];
        for (let i = 0; i < numericArr.length; i++) {
          newArray = [...newArray, value[a]];
          a++;
        }
        newSeatArr.push(newArray);
      }
      setArr(newSeatArr);
      return newSeatArr;
    }
  };

  // Handle ITS number submission
  const handleSubmit = () => {
    setIsLoading(true);
    const regex = /[^a-zA-Z0-9]/g;
    // Replace special characters with an empty string
    const onlyIts = itsNumber.replace(regex, '');
      const trimmedNumbers = onlyIts.toString()
      .match(/.{1,8}/g)
      .filter((num) => num !== "" && num.length === 8) 
    const uniqueNumbers = [...new Set(trimmedNumbers)];
    const existingITSIDs = seatArray.map((seat) => seat.ITSID);
    const uniqueITS = uniqueNumbers.filter((num) =>
      !existingITSIDs.includes(num) 
    );
    if(uniqueITS.length === 0){
      alert("No new ITS Found");
      setIsLoading(false);
      return;
    }
    setItsNumbers(uniqueITS);
   
  };

  // Fetch ITS data for valid ITS numbers
  useEffect(() => {
    const fetchData = async () => {
      if (itsNumbers.length > 0) {
        try {
          const results = await Promise.all(
            itsNumbers.map(async (numberIts) => {
              const response = await axios.get(
                `https://www.idaratalzakereen.org/api/MuminDetails/Get?ITSId=${numberIts}`
              );
              return response.data;
            })
          );

          const validResults = results.filter(
            (data) => data && Object.keys(data).length > 0
          );

          const newData = validResults.filter(
            (data) => !fetchedNumbers.includes(data.ITSID)
          );

          const invalidGenderITS = newData
            .filter((data) => data.Gender === "F")
            .map((data) => data.ITSID);
          if (invalidGenderITS.length > 0) {
            alert(
              `Invalid gender ITS numbers found (Female): ${invalidGenderITS.join(
                ", "
              )}`
            );
          }

          const validGenderData = newData.filter((data) => data.Gender !== "F");

          setItsData((prevData) => [...prevData, ...validGenderData]);
          setFetchedNumbers((prevFetched) => [
            ...prevFetched,
            ...validGenderData.map((item) => item.ITSID),
          ]);
        } catch (error) {
          console.error("Error fetching data", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [itsNumbers]);

  // Delete an ITS number from the list
  const deleteItsNumber = (ITSID) => {
    setItsData((prevData) => prevData.filter((item) => item.ITSID !== ITSID));
    setFetchedNumbers((prevFetched) =>
      prevFetched.filter((number) => number !== ITSID)
    );
  };

  // Handle seat checkbox change
  const handleSeatCheckboxChange = (e, seatId) => {
    setSelectedSeats((prevSelected) => {
      const newSelected = [...prevSelected];
      const seat = seatArray.find((seat) => seat.id === seatId);
      if (!seat || seat.seatCheckStatus || seat.seatStatus === "permanent" || seat.seatStatus === "final" || seat.seatStatus === "temporary") {
        return newSelected;
      }
      if (e.target.checked) {
        if (!newSelected.includes(seatId)) {
          newSelected.push(seatId);
        }
      } else {
        return newSelected.filter((id) => id !== seatId);
      }
      return newSelected;
    });
  };

  // Handle row checkbox change
  const handleRowCheckboxChange = (e, rowIndex) => {
    const allSeatIdsInRow = arr[rowIndex]
      .filter(
        (seat) =>
          seat && !seat.seatCheckStatus &&
          (seat.seatStatus !== "permanent" && seat.seatStatus !== "final" && seat.seatStatus !== "temporary")
      )
      .map((seat) => seat.id);

    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = [...prevSelectedRows];
      if (e.target.checked) {
        newSelectedRows.push(rowIndex);
      } else {
        return newSelectedRows.filter((index) => index !== rowIndex);
      }
      return newSelectedRows;
    });

    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = [...prevSelectedSeats];
      if (e.target.checked) {
        allSeatIdsInRow.forEach((seatId) => {
          if (!newSelectedSeats.includes(seatId)) {
            newSelectedSeats.push(seatId);
          }
        });
      } else {
        return newSelectedSeats.filter((seatId) => !allSeatIdsInRow.includes(seatId));
      }
      return newSelectedSeats;
    });
  };

  // Handle column checkbox change
  const handleColumnCheckboxChange = (e, columnIndex) => {
    const allSeatIdsInColumn = arr
      .map((row) => row[columnIndex])
      .filter(
        (seat) =>
          seat && !seat.seatCheckStatus &&
          (seat.seatStatus !== "permanent" && seat.seatStatus !== "final" && seat.seatStatus !== "temporary")
      )
      .map((seat) => seat.id);

    setSelectedColumns((prevSelectedColumns) => {
      const newSelectedColumns = [...prevSelectedColumns];
      if (e.target.checked) {
        newSelectedColumns.push(columnIndex);
      } else {
        return newSelectedColumns.filter((index) => index !== columnIndex);
      }
      return newSelectedColumns;
    });

    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = [...prevSelectedSeats];
      if (e.target.checked) {
        allSeatIdsInColumn.forEach((seatId) => {
          if (!newSelectedSeats.includes(seatId)) {
            newSelectedSeats.push(seatId);
          }
        });
      } else {
        return newSelectedSeats.filter((seatId) => !allSeatIdsInColumn.includes(seatId));
      }
      return newSelectedSeats;
    });
  };

  const renderColumnCheckboxes = () => {
    if (_.isEmpty(seatArray)) return null;

    const columnCount =
      miqaatDetail.numericLastRange - miqaatDetail.numericFirstRange + 1;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "7rem",
        }}
      >
        {Array.from({ length: columnCount }).map((_, colIndex) => (
          <input
            key={colIndex}
            type="checkbox"
            onChange={(e) => handleColumnCheckboxChange(e, colIndex)}
            style={{
              margin: `.5rem 0`,
            }} // Hide column checkboxes
            checked={selectedColumns.includes(colIndex)}
          />
        ))}
      </div>
    );
  };


  // Loop ITS data with selected seats
  const createDataArray = (itsData, selectedSeats) => {
   
    return itsData.map((item, index) => {
      const selectedSeat = selectedSeats[index];
      return {
        seatStatus: seatStatus,
        ITSID: item.ITSID,
        muminName: item.Name,
        Jamaat: item.Jamaat,
        Jamiat: item.Jamiat,
        muminAge: item.Age,
        muminPhotograph: item.Photo,
        seatAllocationId: selectedSeat,
        grade: item.Grade,
        contactNum: item.Contact,
        miqaat_id: miqaatID,
      };
    });
  };

  // Confirm allocation of selected seats
  const sendDataToBackend = async (itsData, selectedSeats) => {
    if (selectedSeats.length < itsData.length) {
      alert(
        `The number of selected seats is less than the number of ITS IDs. Please select more seats.`
      );
      return;
    }
    const dataToSend = createDataArray(itsData, selectedSeats);

    if (dataToSend.length === 0) {
      alert("No data to Allocate");
      return;
    }

    try {
      const response = await axios.patch(
        `${BASE_URL}/api/miqaat-history/seatAllocation/update`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setItsData([]);
      setSelectedSeats([]);
      setSelectedRows([]);
      setSelectedColumns([]);
      setItsData([]);
      setItsNumbers([]);
      setFetchedNumbers([]);
      setItsNumber("");
      // Fetch updated miqaat data
      fetchMiqaatData();
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const deleteHandler = async (id, itsId) => {
    const data = {
      id: id,
      ITSID: itsId,
    };
    try {
      await axios
        .post(
          `${BASE_URL}/api/miqaat-history/deleteItsData`,
          data,

          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      fetchMiqaatData();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
    <NavLink className="active" to={`/miqaat-history/seatchart/${id}`}>Back</NavLink>
      <div id="report" className="seat-allocation miqaat">
        <h1>
          {miqaatDetail.miqaatName} {miqaatDetail.city}
        </h1>
        <div className="seatChartDate">
          <span className="seatChartHdate">{miqaatDetail.hijriDate}</span>
          <span className="seatChartEdate">{miqaatDetail.date}</span>
        </div>
        <div className="d-flex align-items-center justify-content-around ">
          <textarea
            type="text"
            className="form-control form-control-lg bulk-allocation-textarea"
            placeholder="Enter ITS"
            name="bulkallocation"
            value={itsNumber}
            onChange={(e) => setItsNumber(e.target.value)}
            required
          />

          <button
            className="btn btn-danger m-2"
            onClick={handleSubmit}
            disabled={isLoading || itsNumber.trim() === ""}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
        {itsData.length > 0 && (
          <div className="bulkAllocationTable table mt-4">
            <table>
              <thead className="bulkAllocationTableHeader">
                <tr style={{ backgroundColor: "#D9D9D9" }}>
                  <th>S.No</th>
                  <th>Photo</th>
                  <th>ITS</th>
                  <th>Name</th>
                  <th>Jamaat</th>
                  <th>View Audio</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody className="bulkAllocationTableBody">
                {itsData.map((item, index) => (
                  <tr key={item.ITSID}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="mumin-image"
                        src={item.Photo ? item.Photo : image}
                        alt="mumineenimage"
                      />
                    </td>
                    <td>{item.ITSID}</td>
                    <td>{item.Name}</td>
                    <td>{item.Jamaat}</td>
                    <td>
                      <Popup
                        trigger={
                          <span className="text-primary" type="button">
                            <u>View</u>
                          </span>
                        }
                        position="bottom"
                        contentStyle={{
                          backgroundColor: "white",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          padding: "1rem",
                          borderRadius: "8px",
                        }}
                      >
                        <div className="mt-4 audio-div">
                          {item.audios ? (
                            item.audios.map((audio, index) => (
                              <div key={index + 1}>
                                <ReactAudioPlayer
                                  src={audio}
                                  autoPlay={false}
                                  controls
                                />
                              </div>
                            ))
                          ) : (
                            <div>No audio available</div>
                          )}
                        </div>
                      </Popup>
                    </td>
                    <td>
                      <span
                        className="text-primary "
                        type="button"
                        onClick={() => deleteItsNumber(item.ITSID)}
                      >
                        <u>Delete</u>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="row seat-chart mt-4 bulk-seat-chart">
          {renderColumnCheckboxes()}
          {miqaatDetail.seatChart !== null ? (
            !_.isEmpty(seatArray) &&
            arr.map((item, rowIndex) => {
              return (
                <div
                  key={rowIndex + 1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => handleRowCheckboxChange(e, rowIndex)}
                    style={{ margin: "0 .5rem" }} // Hide row checkboxes
                    checked={selectedRows.includes(rowIndex)}
                  />
                  {item.map((secondItem, colIndex) =>
                  secondItem !== undefined &&
                    (secondItem.seatCheckStatus ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box blocked"
                      >
                        <span>This Seat is Blocked</span>
                      </div>
                    ) : secondItem.seatStatus === "permanent" || secondItem.seatStatus === "final" ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box permanent"
                      >
                        <span className="temporary-permanent-seatNum">
                          {secondItem.seatNum}
                        </span>
                        <img
                          className="profile-image"
                          // src={secondItem.muminPhotograph}
                          src={`${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`}
                          alt=""
                        />
                        <span className="muminName-its">
                          {secondItem.muminName}
                        </span>
                        <span className="muminName-its">
                          {secondItem.ITSID}
                        </span>
                        <div className="seatFooter">
                      <span className="muminGrade">{secondItem.grade ? secondItem.grade : "-"}</span>
                        <img
                          onClick={() => {
                            deleteHandler(secondItem.id, secondItem.ITSID);
                          }}
                          className="delete_button"
                          src={delete_image}
                          alt=""
                          style={{ marginRight: "15px" }}
                        />
                        </div>
                      </div>
                    ) : secondItem.seatStatus === "temporary" ? (
                      <div
                        key={secondItem.seatNum}
                        className="seat-box temporary"
                      >
                        <span className="temporary-permanent-seatNum">
                          {secondItem.seatNum}
                        </span>

                        <img
                          className="profile-image"
                          // src={secondItem.muminPhotograph}
                          src={`${BASE_URL}/uploads/${secondItem.ITSID}.jpeg`}
                          alt=""
                        />
                        <span className="muminName-its">
                          {secondItem.muminName}
                        </span>
                        <span className="muminName-its">
                          {secondItem.ITSID}
                        </span>
                        <div className="seatFooter">
                      <span className="muminGrade">{secondItem.grade ? secondItem.grade : "-"}</span>
                        <img
                          onClick={() => {
                            deleteHandler(secondItem.id, secondItem.ITSID);
                          }}
                          className="delete_button"
                          src={delete_image}
                          alt=""
                          style={{ marginRight: "15px" }}
                        />
                        </div>
                      </div>
                    ) : (
                      <div key={secondItem.seatNum} className="seat-box">
                        <span className="pending">{secondItem.seatNum}</span>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleSeatCheckboxChange(e, secondItem.id)
                          }
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "0",
                            margin: "5px",
                          }}
                          checked={selectedSeats.includes(secondItem.id)}
                          disabled={secondItem.seatCheckStatus}
                        />
                      </div>
                    )
              ))}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      <button
        className="d-flex btn btn-danger mx-auto my-3 p-2 "
        onClick={() => sendDataToBackend(itsData, selectedSeats)}
      >
        Allocate Seats
      </button>
    </>
  );
};

export default BulkAllocation;
