import axios from "axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faTimes } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from "../components/utils/Url";
import { useAuthContext } from "../hooks/useAuthContext";
import MiqaatTable from "./MiqaatTable";

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const Miqat = () => {
  const { user } = useAuthContext();

  const [miqaatTable, setMiqaatTable] = useState([]);
  const [seatArray, setSeatArray] = useState([]);
  const [arr, setArr] = useState([]);
  const [error, setError] = useState("");
  const [selectedMiqaatId, setSelectedMiqaatId] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [miqaatTypeFilter, setMiqaatTypeFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [userFilter, setUserFilter]= useState("");
  const [miqatDetails, setMiqaatDetails] = useState({
    miqaatName: "",
    hijriDate: "",
    date: "",
    city: "",
    alphabeticFirstRange: "",
    alphabeticLastRange: "",
    numericFirstRange: "",
    numericLastRange: "",
    seatChart: seatArray,
    allocationCount: 0,
    miqaatType: "",
    createdBy: "",
  });
  const [updateMiqatDetails, setUpdateMiqaatDetails] = useState({
    miqaatName: "",
    hijriDate: "",
    date: "",
    city:"",
    miqaatType:""
  });

  const fetchMiqaatData = async () => {
    try {
      const result = await axios.get(`${BASE_URL}/api/miqaat-history`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: {
          page: pagination.page,
          limit: pagination.limit,
          search,
          miqaatType:miqaatTypeFilter,
          createdBy:userFilter
        },
      });
      setMiqaatTable(result.data.data.miqaatData);
      if (miqaatTable.length !== 0) {
        setError("");
      }
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  const updateMiqaat = async (miqaatId) =>{
    try {
      const result = await axios.patch(`${BASE_URL}/api/miqaat-history/update-miqaat/${miqaatId}`, updateMiqatDetails, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        }});
      

    } catch (error) {
      console.log(error.message);
    }
    fetchMiqaatData();
      setUpdateMiqaatDetails({
        miqaatName: "",
        hijriDate: "",
        date: "",
        city: "",
        miqaatType: ""
      }) 
  }

  const deleteMiqaat = async (id) => {
    const result = await axios
      .post(
        `${BASE_URL}/api/miqaat-history/deleteData/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        setError(error.message);
      });
    fetchMiqaatData();
  };
  const insertMiqaatData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/miqaat-history/create-miqaat`,
        miqatDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const newData = response.data;
      // Handle the response if necessary
    } catch (error) {
      console.log("Error during data insertion:", error);
    }

    fetchMiqaatData();
    setMiqaatDetails({
      miqaatName: "",
      hijriDate: "",
      date: "",
      city: "",
      alphabeticFirstRange: "",
      alphabeticLastRange: "",
      numericFirstRange: "",
      numericLastRange: "",
      seatChart: [],
      miqaatType: "",
    });
    setSeatArray([]);
  };

  const loadUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/user/fetch`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  };
  useEffect(() => {
    if(user.role==="superAdmin"){
      loadUsers();}
  }, []);

  let numericArr = [];
  const Numeric = (a, b) => {
    for (let i = a; i <= b; i++) {
      numericArr.push(i);
    }
    return numericArr;
  };
  let newArr = [];
  const alpha = (a, b) => {
    let index = alphabets.indexOf(a);
    if (b.length < 2 && a.length < 2) {
      for (let i = index; i <= alphabets.indexOf(b); i++) {
        newArr.push(alphabets[i]);
      }
    } else {
      for (let i = index; i < alphabets.length; i++) {
        newArr.push(alphabets[i]);
      }
      let k = "";
      for (let i = 0; i < alphabets.length; i++) {
        for (let j = 0; j < alphabets.length; j++) {
          k = alphabets[i] + alphabets[j];
          newArr.push(k);
          if (k === b) {
            break;
          }
        }
        if (k === b) {
          break;
        }
      }
    }

    return newArr;
  };

  const seatChart = (alphaArray, numericArray) => {
    let value = [];
    for (let i = 0; i < alphaArray.length; i++) {
      for (let j = 0; j < numericArray.length; j++) {
        let seatNumberArray = {
          seatNum: alphaArray[i] + numericArray[j],
          seatCheckStatus: false,
          seatStatus: "unblock",
          miqaatName: miqatDetails.miqaatName,
          itsNumber: "",
          muminName: "",
          muminJamaat: "",
          muminJamiat: "",
          muminAge: "",
          muminPhotograph: "",
          date: miqatDetails.date,
          miqaatId: "",
        };
        value.push(seatNumberArray);
      }
    }

    setMiqaatDetails((prev) => ({ ...prev, seatChart: value }));
    setSeatArray(value);
    newSeatArrangement(value);
  };

  let newarr = [];
  const newSeatArrangement = (value) => {
    let a = 0;
    if (value.length !== 0) {
      for (let j = 0; j < newArr.length; j++) {
        let newArray = [];
        for (let i = 0; i < numericArr.length; i++) {
          newArray = [...newArray, value[a]];
          a++;
        }
        newarr.push(newArray);
      }
      setArr(newarr);
      return newarr;
    }
  };

  useEffect(() => {
    alpha(
      miqatDetails.alphabeticFirstRange.toUpperCase(),
      miqatDetails.alphabeticLastRange.toUpperCase()
    );
    Numeric(miqatDetails.numericFirstRange, miqatDetails.numericLastRange);
    seatChart(newArr, numericArr);
  }, [miqatDetails.numericLastRange]);

  const handleChange = (e) => {
    setMiqaatDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleMiqaatUpdatedChange = (e) => {
    setUpdateMiqaatDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchMiqaatData();
  },[pagination.page, pagination.limit, miqaatTypeFilter, userFilter]);

  const blockSeatHandler = (seatNum) => {
    let updatedSeats = seatArray.map((item) =>
      item.seatNum !== seatNum
        ? item
        : item.seatCheckStatus === true
        ? { ...item, seatStatus: "unblock", seatCheckStatus: false }
        : { ...item, seatStatus: "blocked", seatCheckStatus: true }
    );
    setSeatArray(updatedSeats);

    setMiqaatDetails((prev) => ({ ...prev, seatChart: updatedSeats }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const handleLimitChange = (newLimit) => {
    setPagination((prev) => ({ ...prev, limit: newLimit }));
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
  };
  const handleSubmitSearch = (e)=>{
    fetchMiqaatData();
    setSearch("");
  }

  const handleMiqaatTypeChange = (e) =>{
    setMiqaatTypeFilter(e.target.value);
  }

  const handleClearFilter = () => {
    setSearch("");
    setMiqaatTypeFilter("");
    setUserFilter("");
    fetchMiqaatData();
    
  };

  const handleUserFilterChange = (e) =>{
    setUserFilter(e.target.value);
  }
  const MiqaatDetailsValues =  (id) =>{
    const result =  miqaatTable.find(miqaat => miqaat.miqaatId === id);
    
    console.log(result)
    setUpdateMiqaatDetails({
      miqaatName: result.miqaatName,
    hijriDate: result.hijriDate,
    date: result.data,
    city:result.city,
    miqaatType:result.miqaatType
    })
  }

  return (
    <div className="miqaat">
      <h1>Create Miqaat Form</h1>
      <div className="miqaat-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            insertMiqaatData();
          }}
        >
          <div className="row">
            <div className="col-md-6 col-6">
              <label className="form-label">Enter Miqaat Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter the Miqaat Name"
                onChange={handleChange}
                name="miqaatName"
                value={miqatDetails.miqaatName}
                required
              />
            </div>

            {/* } */}

            <div className="col-md-2 col-6">
              <label className="form-label">Miqaat Hijri Date</label>
              <input
                type="text"
                className="form-control"
                placeholder="DD/MM/YYYY"
                onChange={handleChange}
                name="hijriDate"
                value={miqatDetails.hijriDate}
                required
              />
            </div>
            <div className="col-md-2 col-6">
              <label className="form-label">Miqaat Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="MM/DD/YYYY"
                onChange={handleChange}
                name="date"
                value={miqatDetails.date}
                required
              />
            </div>
            <div className="col-md-2 col-6">
              <label className="form-label">Miqaat City</label>
              <input
                type="text"
                className="form-control"
                value={miqatDetails.city}
                placeholder="Enter City"
                onChange={handleChange}
                name="city"
                required
              />
            </div>
          </div>
          <div className="row miqat-form-second-row">
            <div className="col-md-4">
              <label className="form-label">
                Enter Alphabatical Range of Seats
              </label>
              <div className="d-flex">
                <div className="col-md-6 col-6">
                  <input
                    type="text"
                    className="form-control"
                    value={miqatDetails.alphabeticFirstRange}
                    placeholder="First Range"
                    aria-label="First name"
                    onChange={handleChange}
                    name="alphabeticFirstRange"
                    required
                  />
                </div>
                <div className="col-md-6 col-6">
                  <input
                    type="text"
                    className="form-control mx-4"
                    value={miqatDetails.alphabeticLastRange}
                    placeholder="Last Range"
                    aria-label="Last name"
                    onChange={handleChange}
                    name="alphabeticLastRange"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mx-4">
              <label className="form-label">
                Enter Numerical Range of Seats
              </label>
              <div className="d-flex">
                <div className="col-md-6 col-6">
                  <input
                    type="Number"
                    className="form-control"
                    value={miqatDetails.numericFirstRange}
                    placeholder="First Range"
                    aria-label="First name"
                    onChange={handleChange}
                    name="numericFirstRange"
                    required
                  />
                </div>
                <div className="col-md-6 col-6">
                  <input
                    type="Number"
                    className="form-control mx-4"
                    value={miqatDetails.numericLastRange}
                    placeholder="Last Range"
                    aria-label="Last name"
                    onChange={handleChange}
                    name="numericLastRange"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <label className="form-label">Miqaat Type</label>
              <select
                value={miqatDetails.miqaatType}
                onChange={handleChange}
                name="miqaatType"
                required
              >
                <option value="" disabled>
                  Select Criticality
                </option>
                <option value="critical">Critical</option>
                <option value="non-critical">Non-Critical</option>
              </select>
            </div>
            <div className="col-md-1">
              <button>Submit</button>
            </div>
          </div>
        </form>
        <div className="row seat-chart">
          {miqatDetails.numericLastRange > miqatDetails.numericFirstRange ? (
            arr.map((item, index) => {
              return (
                <div
                  key={index + 1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {item.map((secondItem) => {
                    return secondItem.seatNum !== "undefined" ? (
                      <div key={secondItem.seatNum} className="seat-box">
                        <input
                          type="checkbox"
                          onChange={() => blockSeatHandler(secondItem.seatNum)}
                        />
                        <span>{secondItem.seatNum}</span>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="row mb-5 mt-3">
          <div className="col-md-3 d-flex">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            className="search-bar mb-2 form-control"
          />
          <button className="search-icon" onClick={handleSubmitSearch}><FontAwesomeIcon icon={faSearch} /></button>
          </div>
          <div className="col-md-3">
              
              <select
                value={miqaatTypeFilter}
                onChange={handleMiqaatTypeChange}
                name="miqaatTypeFilter"
              >
                <option value="" disabled>
                  Filter By Criticality
                </option>
                <option value="critical">Critical</option>
                <option value="non-critical">Non-Critical</option>
              </select>
            </div>
            {
              user.role==="superAdmin" &&  
              <div className="col-md-3">
              
              <select
                value={userFilter}
                onChange={handleUserFilterChange}
                name="userFilter"
                
              >
                <option value="" disabled>
                  Filter By Users
                </option>
                {users.length !==0 && users.map((item) => {
                  return (<option key={item.id} value={item.username}>{item.username}</option>)
                })}
              </select>
            </div>
            }
            <button
              className="btn btn-outline-secondary filter-button col-md-2"
              onClick={handleClearFilter}
            >
              Clear Filters <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
        {miqaatTable.length !== 0 ? (
          <MiqaatTable
            miqaatDetails={miqaatTable}
            setSelectedMiqaatId={setSelectedMiqaatId}
            MiqaatDetailsValues={MiqaatDetailsValues}
            currentPage={pagination.page}
            pageLimit={pagination.limit}
          />
          
        ) : (
          <h3 className="text-center mt-4 No-data-screen">No Data Found</h3>
        )}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Miqaat
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h5>Are you sure you want to delete this miqaat?</h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    deleteMiqaat(selectedMiqaatId);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id="updateModal"
          tabIndex="-1"
          aria-labelledby="updateModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Miqaat Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
              <div className="row">
            <div className="col-md-6">
              <label className="form-label">Enter Miqaat Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter the Miqaat Name"
                onChange={handleMiqaatUpdatedChange}
                name="miqaatName"
                value={updateMiqatDetails.miqaatName}
              />
            </div>

            <div className="col-md-3">
              <label className="form-label">Miqaat Hijri Date</label>
              <input
                type="text"
                className="form-control"
                placeholder="DD/MM/YYYY"
                onChange={handleMiqaatUpdatedChange}
                name="hijriDate"
                value={updateMiqatDetails.hijriDate}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Miqaat Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="MM/DD/YYYY"
                onChange={handleMiqaatUpdatedChange}
                name="date"
                value={updateMiqatDetails.date}
              />
            </div>
            
          </div>
          <div className="row miqat-form-second-row">
            <div className="col-md-3">
              <label className="form-label">Miqaat City</label>
              <input
                type="text"
                className="form-control"
                value={updateMiqatDetails.city}
                placeholder="Enter City"
                onChange={handleMiqaatUpdatedChange}
                name="city"
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Miqaat Type</label>
              <select
                value={updateMiqatDetails.miqaatType}
                onChange={handleMiqaatUpdatedChange}
                name="miqaatType"

              >
                <option value="" disabled>
                  Select Criticality
                </option>
                <option value="critical">Critical</option>
                <option value="non-critical">Non-Critical</option>
              </select>
            </div></div>
              </div>
              <div className="modal-footer">
                
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    updateMiqaat(selectedMiqaatId);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {miqaatTable.length !== 0 && <div className="d-flex justify-content-center my-4 gap-5">
        <div className="d-flex justify-content-center align-items-center my-2">
      <div className="pagination-controls">
        <button
          className="btn btn-primary"
          disabled={pagination.page === 1}
          onClick={() => handlePageChange(pagination.page - 1)}
        >
          Previous
        </button>
        <span className="mx-2">Page {pagination.page}</span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(pagination.page + 1)}
          disabled={miqaatTable.length < pagination.limit}
        >
          Next
        </button>
      </div>
    </div>
    <div className="page-limit-dropdown ">
        <span className="my-2">Items per page:</span>
        <select
          className="form-select"
          value={pagination.limit}
          onChange={(e) => handleLimitChange(parseInt(e.target.value))}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      </div>}
        
      
      </div>
    </div>
  );
};

export default Miqat;
