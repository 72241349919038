import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
const Login = () => {


const {state, dispatch } = useAuthContext();
const [userDetail, setUserDetail] = useState({
    username: "",
    password: ""
})
const [error, setError] = useState("")
const navigate = useNavigate();
const handleChange = (e) => 
   { setUserDetail(prevState => ({...prevState, [e.target.name]: e.target.value}))}

   const clickHandler = async () => {
   const valueJsonStringify = {
    "username": `${userDetail.username}`,
    "password": `${userDetail.password}`
   }
        const result = await axios.post("https://allocations.idaratalzakereen.org:8080/api",          valueJsonStringify
        ).then(res => {
          return res.data
        }).catch(res => {
          return res.message
        })
        if (result.status === "success") {
          const userData = {
            token: result.data.token,
            role: result.data.user.role,
            username: result.data.user.username
        };
    
        // Convert the object to a JSON string
        const userDataString = JSON.stringify(userData);
    
        // Save the JSON string in local storage
        localStorage.setItem('userData', userDataString);
    
        // Dispatch the object as payload
        dispatch({ type: 'LOGIN', payload: userData });
          navigate("/miqaat-history")
        } else {
          setError("Please enter correct credentials")
        }
   }

   //  useEffect(() => {
  //   if (state !== undefined) {
  //     navigate("/miqaat-history")
  // }
  //  }, [])

return (
    <div>    
    <main className="form-signin">
  <form>
    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

    <div className="form-floating">
      <input type="text" className="form-control" id="floatingInput" onChange={handleChange} placeholder="User Name" name="username" required/>
      <label htmlFor="floatingInput">User Name</label>
    </div>
    <div className="form-floating">
      <input type="password" className="form-control" id="floatingPassword" onChange={handleChange} placeholder="Password" name="password" required/>
      <label htmlFor="floatingPassword">Password</label>
    </div>

   
    <button className="w-100 btn btn-lg btn-primary" type="button" onClick={clickHandler}>Log in</button>
  </form>
  {error !== "" ? 
  <div className="alert alert-danger mt-4" role="alert">
  {error}
</div> :
<></>}
</main>
</div>


)

}


export default Login;