import { saveAs } from "file-saver";
import { pdf, Document, Page, Text, View, Image } from "@react-pdf/renderer";
import _ from "lodash";
import dummyImage from "../images/dummy.jpg";


const GeneratePDF = async (miqaatDetail, arr, imageBase) => {

  const blob = await pdf(
    <Document>
      <Page
        style={{
          backgroundColor: "#FFFFFF",
          color: "#000",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 700,
          padding: 15,
        }}
        orientation="landscape"
        wrap={false}
      >
        <View style={{ textAlign: "center", fontSize: 20 }}>
          <Text>{miqaatDetail.miqaatName}</Text>
          <Text>
            {miqaatDetail.hijriDate}H -- {miqaatDetail.date}
          </Text>
        </View>
        {/* {.map((item, index) => (
            <View key={item}>
              <View break wrap={false}>
              </View>

            </View>
          ))} */}

        <View
          style={{
            marginTop: 16,
          }}
        >
          {miqaatDetail.seatChart !== null ? (
            !_.isEmpty(arr) &&
            arr.map((item, index) => {
              return (
                <View
                  key={index + 1}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {item.map((secondItem) => {
                              
                    return  (secondItem !== undefined && imageBase !== undefined) &&
                    (secondItem.seatStatus !== undefined &&
                      secondItem.seatCheckStatus ? (
                      <View
                        key={secondItem.seatNum}
                        style={{
                          border: "1px solid #000",
                          width: 125,
                          height: 130,
                          paddingTop: 5,
                          color: "#FFF",
                          fontSize: 9,
                          fontWeight: 500,
                          backgroundColor: "#000",
                        }}
                      >
                        <Text style={{ marginTop: 50, padding: 0 }}>
                          This Seat is Blocked
                        </Text>
                      </View>
                    ) : secondItem.seatStatus === "permanent" || secondItem.seatStatus === "final" ? (
                      <View
                        key={secondItem.seatNum}
                        style={{
                          border: "1px solid #000",
                          width: 125,
                          height: 130,
                          paddingTop: 5,
                          fontSize: 9,
                          fontWeight: 500,
                          backgroundColor: "#91C86F",
                        }}
                      >
                        <Text
                          style={{
                            padding: 0,
                            fontSize: 12,
                          }}
                        >
                          {secondItem.seatNum}
                        </Text>

                        <Image
                          style={{
                            width: 60,
                            marginTop: -15,
                            marginLeft: 25,
                          }}
                            //   src={
                          //     secondItem.muminPhotograph.includes("/9j/")
                          //       ? `${secondItem.muminPhotograph.replace(
                          //           "png",
                          //           "jpeg"
                          //         )}`
                          //       : secondItem.muminPhotograph
                          //   }
                          // src={
                          //  secondItem.muminPhotograph
                          // }
                          src={imageBase.hasOwnProperty(secondItem.ITSID) ? !imageBase[secondItem.ITSID].includes("/9j/") ? 'data:image/png;base64,' + imageBase[secondItem.ITSID] : 'data:image/jpeg;base64,' + imageBase[secondItem.ITSID] : dummyImage}
                          // src={imageBase.hasOwnProperty(secondItem.ITSID) ? 'data:image/png;base64,' + imageBase[secondItem.ITSID] : dummyImage}
                        />
                        <Text
                          style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 3,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.muminName}
                        </Text>
                        <Text
                          style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 3,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.ITSID} {}
                        </Text>
                        <Text
                         style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 3,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.grade ? secondItem.grade : "-"}
                        </Text>
                      </View>
                    ) : secondItem.seatStatus === "temporary" ? (
                      <View
                        key={secondItem.seatNum}
                        style={{
                          border: "1px solid #000",
                          width: 125,
                          height: 130,
                          paddingTop: 5,
                          fontSize: 9,
                          fontWeight: 500,
                          backgroundColor: "#E1AC5C",
                        }}
                      >
                        <Text
                          style={{
                            padding: 0,
                            fontSize: 12,
                          }}
                        >
                          {secondItem.seatNum}
                        </Text>
                        <Image
                          style={{
                            width: 60,
                            marginTop: -15,
                            marginLeft: 25,
                          }}
                          //   src={
                          //     secondItem.muminPhotograph.includes("/9j/")
                          //       ? `${secondItem.muminPhotograph.replace(
                          //           "png",
                          //           "jpeg"
                          //         )}`
                          //       : secondItem.muminPhotograph
                          //   }
                          // src={
                          //  secondItem.muminPhotograph
                          // }
                          src={imageBase.hasOwnProperty(secondItem.ITSID) ? !imageBase[secondItem.ITSID].includes("/9j/") ? 'data:image/png;base64,' + imageBase[secondItem.ITSID] : 'data:image/jpeg;base64,' + imageBase[secondItem.ITSID] : dummyImage}

                          // src={imageBase.hasOwnProperty(secondItem.ITSID) ? !imageBase.hasOwnProperty(secondItem.ITSID).includes("/9j/") ? 'data:image/png;base64,' + imageBase[secondItem.ITSID] : imageBase[secondItem.ITSID] : dummyImage}
                        />
                        <Text
                          style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 6,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.muminName}
                        </Text>
                        <Text
                          style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 3,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.ITSID}
                        </Text>
                        <Text
                         style={{
                            fontSize: 7,
                            padding: 0,
                            paddingTop: 6,
                            textAlign: "center",
                          }}
                        >
                          {secondItem.grade ? secondItem.grade : "-"}
                        </Text>
                      </View>
                    ) : (
                      <View
                        key={secondItem.seatNum}
                        style={{
                          border: "1px solid #000",
                          width: 125,
                          height: 130,
                          paddingTop: 5,
                          fontSize: 9,
                          fontWeight: 500,
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <Text
                          style={{
                            padding: 50,
                            paddingBottom: 0,
                          }}
                        >
                          {secondItem.seatNum}
                        </Text>
                      </View>
                    )
              )})}
                </View>
              );
            })
          ) : (
            <></>
          )}
        </View>

        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  ).toBlob();

  saveAs(blob, miqaatDetail.miqaatName);
};

export default GeneratePDF;
