import React, { useEffect, useState } from "react";
import image from "../images/dummy.jpg";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import ReactAudioPlayer from "react-audio-player";
import { BASE_URL } from "./utils/Url";

const ItsData = ({ getItsData, seatDetail, seatArray }) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [prevRecord, setPrevRecord] = useState([]);
  const [numberIts, setNumberIts] = useState(seatDetail.ITSID !== null ? seatDetail.ITSID : "");
  const { user } = useAuthContext();

  const permanentClickHandler = () => {
    const seatStatus = 'permanent';
    getItsData(data, seatStatus, seatDetail.id);
    setShow(false);
  };

  const temporaryClickHandler = () => {
    const seatStatus = 'temporary';
    getItsData(data, seatStatus, seatDetail.id);
    setShow(false);
  };

  const inputHandler = (e) => {
    const value = e.target.value.trim();
    setNumberIts(value);
  };

  useEffect(() => {
    if (numberIts.length === 8 && numberIts !== null) {
      fetchIts();
    } else if (numberIts.length === 8 && numberIts !== null) {
      setShow(true);
    }
  }, [numberIts]);

  const fetchIts = async () => {
    const result = await axios
      .get(
        `https://www.idaratalzakereen.org/api/MuminDetails/Get?ITSId=${numberIts}`
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error.message;
      });
    if (result) {
      setShow(true);
    }
    setData(result);
  };

  const fetchPreviousRecord = async (its) => {
    const result = await axios
      .get(`${BASE_URL}/api/miqaat-history/getItsData/${its}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.message);
    setPrevRecord(result.data);
  };
  return (
    <div className="itsTooltip">
      <input
        type="text"
        placeholder="Enter ITS"
        className="popover-search"
        maxLength="8"
        minLength="8"
        onChange={inputHandler}
        value={numberIts}
        required
      />

      {show ? 
        seatDetail.ITSID !== null ? 
      (  <div className="tooltip-body mt-4">
              <div className="d-flex">
                <div>
                  <img
                    className="mumin-image"
                    src={data.Photo ? data.Photo : image}
                    alt=""
                  />
                </div>
                <div className="d-grid">
                  <span>
                    <strong>Name:</strong> {data.Name ? data.Name : "-"}{" "}
                  </span>
                  <span>
                    <strong>Contact:</strong>{" "}
                    {data.Contact ? data.Contact : "-"}{" "}
                    <span className="mx-3">
                      <strong>Age:</strong> {data.Age ? data.Age : "-"}{" "}
                    </span>
                  </span>
                  <span>
                    <strong>Idara:</strong> {data.Idara ? data.Idara : "-"}{" "}
                    <span className="mx-3">
                      <strong>ID Card:</strong>{" "}
                      {data.IDCard ? data.IDCard : "-"}{" "}
                    </span>
                  </span>
                  <span>
                    <strong>Jamiat:</strong> {data.Jamiat ? data.Jamiat : "-"}{" "}
                    <span className="mx-3">
                      <strong>Grade:</strong> {data.Grade ? data.Grade : "-"}
                    </span>
                  </span>
                  <span>
                    <strong>Jamaat:</strong> {data.Jamaat ? data.Jamaat : "-"}
                  </span>
                  <button
                    className="btn btn-link p-0"
                    onClick={() => {
                      fetchPreviousRecord(numberIts);
                      setOpen(!open);
                    }}
                    style={{ fontSize: 12, textAlign: "left" }}
                  >
                    Click Here To See Previous Allocation
                  </button>
                </div>
              </div>
              {open &&
                (prevRecord.length !== 0 ? (
                  <div
                    className="miqaatTable table"
                    style={{ width: 425, height: 317, overflow: "scroll" }}
                  >
                    <table>
                      <thead className="miqaatTableHeader">
                        <th className="text-center">S.No</th>
                        <th>Miqaat</th>
                        <th className="text-center">Venue</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Seat Number</th>
                      </thead>
                      <tbody className="miqaatTableBody">
                        {prevRecord.map((item, index) => {
                          return (
                            <tr key={item.miqaatId}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item.miqaatName}</td>
                              <td className="text-center">{item.city}</td>
                              <td className="text-center">{item.date}</td>
                              <td className="text-center">{item.seatNum}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="mt-4">No Records Found</div>
                ))}

              <div className="mt-4 audio-div">
                {data.audios !== null
                  ? data.audios.map((item, index) => {
                      return (
                        <div key={index + 1}>
                          {/* <span>{item.name}</span> */}
                          <ReactAudioPlayer
                            src={item}
                            autoPlay={false}
                            controls
                          />
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className="d-flex">
                <button
                  className="tooltip-footer-btn"
                  onClick={permanentClickHandler}
                >
                  Permanent
                </button>
                <button
                  className="tooltip-footer-btn"
                  onClick={temporaryClickHandler}
                >
                  Temporary
                </button>
              </div>
            </div>)
            :
      (
        seatArray.some(seat => seat.ITSID === numberIts) ? (
          <div className="mt-4 text-center">
            ITS already Allocated for this miqaat
          </div>
        ) 
        : 
        numberIts.length < 8 || numberIts === undefined ? (
          <div className="mt-4 text-center">Please Enter Correct ITS ID</div>
        ) 
        :
        (data.Name === null || data.Name === "" || data.Gender === "F") ? (
          <div className="mt-4 text-center">Please Enter Correct ITS ID</div>
        ) 
        : 
        seatDetail.ITSID === numberIts ? (
          numberIts !== undefined ? (
            <div className="tooltip-body mt-4">
              <div className="d-flex">
                <div>
                  <img
                    className="mumin-image"
                    src={data.Photo ? data.Photo : image}
                    alt=""
                  />
                </div>
                <div className="d-grid">
                  <span>
                    <strong>Name:</strong> {data.Name ? data.Name : "-"}{" "}
                  </span>
                  <span>
                    <strong>Contact:</strong>{" "}
                    {data.Contact ? data.Contact : "-"}{" "}
                    <span className="mx-3">
                      <strong>Age:</strong> {data.Age ? data.Age : "-"}{" "}
                    </span>
                  </span>
                  <span>
                    <strong>Idara:</strong> {data.Idara ? data.Idara : "-"}{" "}
                    <span className="mx-3">
                      <strong>ID Card:</strong>{" "}
                      {data.IDCard ? data.IDCard : "-"}{" "}
                    </span>
                  </span>
                  <span>
                    <strong>Jamiat:</strong> {data.Jamiat ? data.Jamiat : "-"}{" "}
                    <span className="mx-3">
                      <strong>Grade:</strong> {data.Grade ? data.Grade : "-"}
                    </span>
                  </span>
                  <span>
                    <strong>Jamaat:</strong> {data.Jamaat ? data.Jamaat : "-"}
                  </span>
                  <button
                    className="btn btn-link p-0"
                    onClick={() => {
                      setOpen(!open);
                    }}
                    style={{ fontSize: 12, textAlign: "left" }}
                  >
                    Click Here To See Previous Allocation
                  </button>
                </div>
              </div>
              {open &&
                (prevRecord.length !== 0 ? (
                  <div
                    className="miqaatTable table"
                    style={{ width: 356, height: 317, overflow: "scroll" }}
                  >
                    <table>
                      <thead className="miqaatTableHeader">
                        <th className="text-center">S.No</th>
                        <th>Miqaat</th>
                        <th className="text-center">Venue</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Seat Number</th>
                      </thead>
                      <tbody className="miqaatTableBody">
                        {prevRecord.map((item, index) => {
                          return (
                            <tr key={item.miqaatId}>
                              <td className="text-center">{index + 1}</td>
                              <td>{item.miqaatName}</td>
                              <td className="text-center">{item.city}</td>
                              <td className="text-center">{item.date}</td>
                              <td className="text-center">{item.seatNum}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="mt-4">No Records Found</div>
                ))}

              <div className="mt-4 audio-div">
                {data.audios !== null
                  ? data.audios.map((item, index) => {
                      return (
                        <div key={index + 1}>
                          {/* <span>{item.name}</span> */}
                          <ReactAudioPlayer
                            src={item}
                            autoPlay={false}
                            controls
                          />
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className="d-flex">
                <button
                  className="tooltip-footer-btn"
                  onClick={permanentClickHandler}
                >
                  Permanent
                </button>
                <button
                  className="tooltip-footer-btn"
                  onClick={temporaryClickHandler}
                >
                  Temporary
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-4 text-center">
              ITS already Allocated for this miqaat
            </div>
          )
        ) 
        : (
          <div className="tooltip-body mt-4">
            <div className="d-flex">
              <div>
                <img
                  className="mumin-image"
                  src={data.Photo ? data.Photo : image}
                  alt=""
                />
              </div>
              <div className="d-grid">
                <span>
                  <strong>Name:</strong> {data.Name ? data.Name : "-"}{" "}
                </span>
                <span>
                  <strong>Contact:</strong> {data.Contact ? data.Contact : "-"}{" "}
                  <span className="mx-3">
                    <strong>Age:</strong> {data.Age ? data.Age : "-"}{" "}
                  </span>
                </span>
                <span>
                  <strong>Idara:</strong> {data.Idara ? data.Idara : "-"}{" "}
                  <span className="mx-3">
                    <strong>ID Card:</strong> {data.IDCard ? data.IDCard : "-"}{" "}
                  </span>
                </span>
                <span>
                  <strong>Jamiat:</strong> {data.Jamiat ? data.Jamiat : "-"}{" "}
                  <span className="mx-3">
                    <strong>Grade:</strong> {data.Grade ? data.Grade : "-"}
                  </span>
                </span>
                <span>
                  <strong>Jamaat:</strong> {data.Jamaat ? data.Jamaat : "-"}
                </span>
                <button
                  className="btn btn-link p-0"
                  onClick={() => {
                    fetchPreviousRecord(numberIts);
                    setOpen(!open);
                  }}
                  style={{ fontSize: 12, textAlign: "left" }}
                >
                  Click Here to see Previous Allocation
                </button>
              </div>
            </div>
            {open &&
              (prevRecord.length !== 0 ? (
                <div
                  className="miqaatTable table"
                  style={{ width: 500, height: 317, overflow: "scroll" }}
                >
                  <table>
                    <thead className="miqaatTableHeader">
                      <th className="text-center">S.No</th>
                      <th>Miqaat</th>
                      <th className="text-center">Venue</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Seat Number</th>
                    </thead>
                    <tbody className="miqaatTableBody">
                      {prevRecord.map((item, index) => {
                        return (
                          <tr key={item.miqaatId}>
                            <td className="text-center">{index + 1}</td>
                            <td>{item.miqaatName}</td>
                            <td className="text-center">{item.city}</td>
                            <td className="text-center">{item.date}</td>
                            <td className="text-center">{item.seatNum}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Records Found</div>
              ))}

            <div className="mt-4 audio-div">
              {data.audios !== null
                ? data.audios.map((item, index) => {
                    return (
                      <div key={index + 1}>
                        {/* <span>{item.name}</span> */}
                        <ReactAudioPlayer
                          src={item}
                          autoPlay={false}
                          controls
                        />
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="d-flex">
              <button
                className="tooltip-footer-btn"
                onClick={permanentClickHandler}
              >
                Permanent
              </button>
              <button
                className="tooltip-footer-btn"
                onClick={temporaryClickHandler}
              >
                Temporary
              </button>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default ItsData;
