import React, { useState, useMemo } from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Link } from "react-router-dom";

const MiqaatTable = ({ miqaatDetails, setSelectedMiqaatId ,MiqaatDetailsValues, currentPage, pageLimit }) => {
  const [sorting, setSorting] = useState([]);

  

  // Custom sorting function for serial number
  const customSorting = (rowA, rowB) => {
    const indexA = rowA.index;
    const indexB = rowB.index;
    return indexA - indexB;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "sno",
        header: "S.No",
        cell: (info) => ((currentPage - 1) * pageLimit + info.row.index + 1),
        sortingFn: customSorting, // Use the custom sorting function
      },
      {
        accessorKey: "miqaatName",
        header: "Miqaat",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "city",
        header: "Venue",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "date",
        header: "Date",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "allocationCount",
        header: "Allocated Seats",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "miqaatType",
        header: "Criticality",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "viewChart",
        header: "View Chart",
        cell: (info) => (
          <Link to={`/miqaat-history/seatchart/${info.row.original.miqaatId}`}>
            View
          </Link>
        ),
        enableSorting: false,
      },
      {
        accessorKey: "editMiqaat",
        header: "Edit",
        cell: (info) => (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {setSelectedMiqaatId(info.row.original.miqaatId)
                MiqaatDetailsValues(info.row.original.miqaatId);
            }}
            data-bs-toggle="modal"
            data-bs-target="#updateModal"
          >
           <strong> Edit</strong>
          </button>
        ),
        enableSorting: false,
      },
      {
        accessorKey: "delete",
        header: "Delete",
        cell: (info) => (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setSelectedMiqaatId(info.row.original.miqaatId)}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Delete
          </button>
        ),
        enableSorting: false,
      },
    ],
    [setSelectedMiqaatId, currentPage, pageLimit, MiqaatDetailsValues]
  );

  const table = useReactTable({
    columns,
    data: miqaatDetails,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting
    },
    manualSorting: false,
  });

  return (
    <>
      
      <div className="miqaatTable table mt-4">
        <table>
          <thead className="miqaatTableHeader">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="miqaatTableBody">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    
    </>
  );
};

export default MiqaatTable;
